import { call, put, select } from "redux-saga/effects";
import { ee } from "../../../../services/earth-engine";

import { callback } from "../../../tools/effects";
import * as Imagery from "../header";
import { Actions as Map } from "../../map";
import { openAndWait } from "../../dialog/saga";

import { applyExpression } from "../../../../algorithms/utils";
import {
  cumulativeWindow,
  generateLayer,
} from "../../../../algorithms/imagery";
import { ConcreteLayer } from "../../../../common/classes";
import * as Selectors from "../../../../selectors";
import {BathymetryResults} from "../../../../app/pages/ProcessingBathymetryPage";

export class RawMapHelper{
  static lastRowMapId = null;
}


export const handleLoadLayer = function* ({ payload: { layer, parent } }) {
  //console.log("handleLoadLayer (0)");
  const { imageId, layerId } = yield select(Selectors.getImageryIdentifiers);
 // console.log("handleLoadLayer (1)", "imageId", imageId,"layerId", layerId);
  if (isNaN(parent)) {
    yield put(Imagery.Actions.pushImage(parent));
    parent = imageId;
  }

  let { image, params } = layer;
  //console.log("handleLoadLayer (2)", "image", image,"params", params, "layer", layer);
  yield put({ type: "EVALUATE" });
  //console.log("handleLoadLayer (3)", "map", image.getMap); //window.initMap, attention here *21.10.22 => force add map may solve
  const rawMapId = yield callback([image, image.getMap], params);
  //console.log("rawMapId", rawMapId );
  RawMapHelper.lastRowMapId = rawMapId;
  yield put({ type: "DONE" });

  const source = new ee.layers.EarthEngineTileSource(rawMapId);
  //console.log("handleLoadLayer (4)", source );
  const overlay = new ee.layers.ImageOverlay(source);
 // console.log("handleLoadLayer (5)", overlay );
  const concrete = new ConcreteLayer(layer, overlay);
  //console.log("handleLoadLayer (6)", concrete  );
  const position = yield select(Selectors.computeInsertionIndex(parent));
 // console.log("handleLoadLayer (7)", position );
  yield put(Imagery.Actions.pushLayer(concrete, parent));
 // console.log("handleLoadLayer (8)", "push layer" );
  yield put(Map.addEELayer(overlay, layerId, position));
  //console.log("handleLoadLayer (9)", "add ee layer" );
};

export const handleToggleVisibility = function* ({ payload: { identifier } }) {
  const layer = yield select(Selectors.getLayer(identifier));

  const newVisibility = layer.visible ? 0 : 1;

  yield put(Map.changeOpacity(identifier, newVisibility));
  yield put(
    Imagery.Actions.commitChange(identifier, { visible: newVisibility })
  );
};

export const handleUpdateOpacity = function* ({
  payload: { identifier, opacity },
}) {
  yield put(Map.changeOpacity(identifier, opacity));
  yield put(Imagery.Actions.commitChange(identifier, { opacity }));
};


export const handleRemoveLayer = function* ({
  payload: { identifier },
}) {
  yield put(Map.removeLayer(identifier));
  yield put(Imagery.Actions.removeElement(identifier));
};


export const handleRequestExpression = function* ({ payload: { parent } }) {
  const { name, expression } = yield call(openAndWait, "newLayer");
  
  if (expression) {
    if(BathymetryResults.bathymetryImage === null){
      const { geometry, satellite } = yield select(
        Selectors.getAcquisitionParameters
      );
    
      const { date, missionName } = yield select(
        (state) => state.imagery.images[parent]
      );
      const mission = satellite.get(missionName);
      const image = mission.algorithms.acquire(date, geometry);
      const target = "band_exp";
      const modified = applyExpression(image, expression, mission.bands).rename(
        target
      );

      /* Calculate visualization parameters (min/max)
      * Issue #47 */
      const reducer = {
        reducer: ee.Reducer.histogram(),
        scale: mission.opticalResolution,
        geometry,
      };
      const histogram = modified.reduceRegion(reducer).get(target);
      const window = cumulativeWindow(histogram, 0.01);
      const [min, max] = yield callback([window, window.evaluate]);
      // console.log("GenerateLayer(layers.js)", modified,modified.getInfo(), satellite);
      const layer = generateLayer(modified, satellite, name, { min, max });
      console.log("generateLayer(layer object)", layer, "parent",parent ); 
      yield put(Imagery.Actions.loadLayer(layer, parent));
    }else if (!BathymetryResults.showMedianLayer){
      console.log("Handling requested expression... Show Bathymetry image");
      let modified = BathymetryResults.bathymetryImage;
      const params = BathymetryResults.bathymetryParams;
      const satellite = BathymetryResults.mission;
      modified = modified.clip(params.region);
      //console.log("GenerateLayer(layers.js)", "region", params.region);
      //console.log("GenerateLayer(layers.js)", modified,modified.getInfo(), satellite);
      const layer = generateLayer(modified, satellite, name, params);
      //console.log("generateLayer(layer object)", layer, "parent",parent ); 
      yield put(Imagery.Actions.loadLayer(layer, parent));
    }else if(BathymetryResults.showMedianLayer){
      console.log("Handling requested expression... Show Median Image");
      let modified = BathymetryResults.eeImageToProcess;
      /*
      console.log("Using median image");
      let params = BathymetryResults.medianImageParams;
      let medianImageResult = BathymetryResults.getMedianImage(params[0], params[1], params[2], params[3]);   
      */
      const region = BathymetryResults.bathymetryParams.region;
      const params = {region: region,bands:['B4', 'B3', 'B2'], max:1700, min:300};//BathymetryResults.bathymetryParams;
      const satellite = BathymetryResults.mission; 
      modified = modified.clip(params.region);
      //console.log("GenerateLayer(layers.js)", "region", params.region);
      //console.log("GenerateLayer(layers.js)", modified,modified.getInfo(), satellite);
      const layer = generateLayer(modified, satellite, "Median", params);
      //console.log("generateLayer(layer object)", layer, "parent",parent ); 
      yield put(Imagery.Actions.loadLayer(layer, parent));
    }
    
  }
};

export const handleRequestExpressionOriginal = function* ({ payload: { parent } }) {
  const { name, expression } = yield call(openAndWait, "newLayer");

  if (expression) {
    const { geometry, satellite } = yield select(
      Selectors.getAcquisitionParameters
    );

    const { date, missionName } = yield select(
      (state) => state.imagery.images[parent]
    );

    const mission = satellite.get(missionName);
    const image = mission.algorithms.acquire(date, geometry);

    const target = "band_exp";
    const modified = applyExpression(image, expression, mission.bands).rename(
      target
    );

    /* Calculate visualization parameters (min/max)
     * Issue #47 */
    const reducer = {
      reducer: ee.Reducer.histogram(),
      scale: mission.opticalResolution,
      geometry,
    };
    const histogram = modified.reduceRegion(reducer).get(target);
    const window = cumulativeWindow(histogram, 0.01);
    const [min, max] = yield callback([window, window.evaluate]);
    // console.log("GenerateLayer(layers.js)", modified,modified.getInfo(), satellite);
    const layer = generateLayer(modified, satellite, name, { min, max });
    console.log("generateLayer(layer object)", layer, "parent",parent ); 
    yield put(Imagery.Actions.loadLayer(layer, parent));
  }
};
