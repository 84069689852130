import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { omit } from "lodash";

import {
  AppBar,
  Button,
  Menu,
  MenuItem,
  Paper,
  Tab,
  Tabs,
} from "@material-ui/core";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";

import TransectDataTable from "../visualization/TransectDataTable";
import Export from "../../../services/export";
import { exportCSV, exportJSON } from "../../../common/utils";
import { INTERNALS } from "../../../common/metadata";
import { ResultsCacheHolder } from "../../../store/ducks/imagery/saga/shoreline";
import { ContactSupportOutlined, WbIncandescentOutlined } from "@material-ui/icons";

const shapeTransectData = (transectData) => {
  return transectData.map(({ id, properties }) => ({
    id: parseInt(id, 10),
    ...omit(properties, [INTERNALS]),
  }));
};

let intervalCodeShowErrorShorelineImages = null;
function showErrorShorelineImages(){
  const val = window.sessionStorage.getItem("shorelineImagesErrors");
  if (val === null) return;
  const list = JSON.parse(val);
  let component = document.getElementById("shoreline_images_errors");
  if(list.length > 0 && component !== null ){
    component.style.display = "block";
    component.value = "Images not processed ("+list.length+"):";
    list.forEach(el=>{
      component.value +="\n" + el.dateFormated + " - " + el.message;
    }) ;
    clearInterval(intervalCodeShowErrorShorelineImages);
  }
}
function sortOutput(data){  // TODO make nan safe, test corner cases, improve sorting, separate date and distance treatment in functions
  data.forEach(el=>{
    let txtDates = el.dates;
    let txtDistances = el.distances;
    txtDates = txtDates.replace("\"","").replace("\"","").replace("[","").replace("]","");
    txtDistances = txtDistances.replace("\"","").replace("\"","").replace("[","").replace("]","");
    let datesTxtArray = txtDates.split(",");
    let distancesTxtArray =  txtDistances.split(",");
    let dates = [];
    dates = datesTxtArray.map(dtTxt => new Date(dtTxt) );
   
    //console.log(txtDates,dates);
    //ordenar dentro da célula e depois reordenar as distancias de forma correspondente.
   //bubblesort

   let length = dates.length;
   let swapped = false;
   do {
       swapped = false;
       for (let i = 0; i < length; i++) {
           if (dates[i] > dates[i + 1]) {
               let tmp = dates[i];
               dates[i] = dates[i + 1];
               dates[i + 1] = tmp;


               tmp = distancesTxtArray[i];
               distancesTxtArray[i] = distancesTxtArray[i + 1];
               distancesTxtArray[i + 1] = tmp;

               swapped = true;
               
           }
       }
   } while (swapped);
    dates = dates.map(el => isFinite(el) ? el.toISOString() : "");
    distancesTxtArray = distancesTxtArray.map(el => Number.parseFloat(el).toFixed(2));
    el.dates = '"' + JSON.stringify(dates).replace(/"/g, "'").replace(/.000Z/g,"") +'"';
    el.distances = '"' + JSON.stringify(distancesTxtArray).replace(/"/g, "'").replace(/'/g, "") +'"' ;
  });
 
  return data;
}


const CoastlineEvolutionDialog = ({ open, close }) => {
  const transects = useSelector((state) =>
    state.results.coastlineData ? state.results.coastlineData.transectData :  ResultsCacheHolder.transacts != null? ResultsCacheHolder.transacts : []
  );
  const baselineData = useSelector((state) =>
    state.results.baselineData ? state.results.baselineData.baseline : {}
  );
  const shorelineData = useSelector((state) =>
    state.results.coastlineData ? state.results.coastlineData.shorelineData : ResultsCacheHolder.shorelines != null? ResultsCacheHolder.shorelines : []
  );
  //console.log("CoastlineEvolutionDialog-DEBUG",transects,shorelineData);
  //console.log("CoastlineEvolutionDialog",transects,baselineData,shorelineData   );
  const transectData = shapeTransectData(transects);
 //console.log("CoastlineEvolutionDialog.jsx","transectData",transects);
  const [t] = useTranslation();

  const [tab, setTab] = useState(0);
  const [transectsAnchorEl, setTransectsAnchorEl] = useState(null);
  if(window.location.href.indexOf("bathymetry") !== -1) return false;
  const tabs = [<TransectDataTable data={transectData} />];
  intervalCodeShowErrorShorelineImages = setInterval(showErrorShorelineImages, 3000);
  return (
    <Dialog open={open} 
    Width="md" onClose={() => close()}>
      <DialogTitle>{t("forms.shorelineAnalysis.title")}</DialogTitle>
      <DialogContent>
        <Paper>
          <AppBar position="static" color="default">
            <Tabs
              value={tab}
              indicatorColor="primary"
              textColor="primary"
              onChange={(e, tab) => setTab(tab)}
            >
              <Tab label={t("forms.shorelineAnalysis.transectsReport.title")} />
            </Tabs>
          </AppBar>
          {tabs[tab]}
          <textarea id="shoreline_images_errors" rows="5" readonly style={ { "width":"100%","display":"none","fontSize":"10"} } ></textarea>
        </Paper>
      </DialogContent>
      <DialogActions>
        {tab === 0 && (
          <div>
            <Button onClick={(e) => setTransectsAnchorEl(e.currentTarget)}>
              {t("forms.shorelineAnalysis.exports.title")}
            </Button>
            <Menu
              anchorEl={transectsAnchorEl}
              open={Boolean(transectsAnchorEl)}
              onClose={() => setTransectsAnchorEl(null)}
            >
              <MenuItem
                onClick={() => {
                  console.log("transectData - CSV", transectData);
                  let data = sortOutput(transectData);
                  exportCSV(data, "transects.csv");
                  setTransectsAnchorEl(null);
                }}
              >
                {t("forms.shorelineAnalysis.exports.csv")}
              </MenuItem>
              <MenuItem
                onClick={() => {
                  let data = sortOutput(transectData);
                  exportJSON(data, "transects.json");
                  setTransectsAnchorEl(null);
                }}
              >
                {t("forms.shorelineAnalysis.exports.json")}
              </MenuItem>
              <MenuItem
                onClick={() => {
                   Export.table.toDevice.asShapefileGroup(
                    [
                      {
                        features: [
                          {
                            geometry: {
                              type: "LineString",
                              coordinates: baselineData.content || [],
                            },
                            properties: {},
                          },
                        ],
                      },
                      
                      {
                        features: shorelineData.getInfo().map((shoreline) => ({
                          ...shoreline,
                          properties: omit(shoreline.properties, [INTERNALS]),
                        })),
                      },
                      
                      {
                        features: transects.map((transect) => ({
                          ...transect,
                          properties: omit(transect.properties, [INTERNALS]),
                        })),
                      },
                    ],
                    Export.defaultOptions.device.shapefileGroup(
                      "layers",
                      "baseline",
                      "coastlines",
                      "transects"
                    )
                  );
                  setTransectsAnchorEl(null);
                }}
              >
                {t("forms.shorelineAnalysis.exports.shp")}
              </MenuItem>
            </Menu>
          </div>
        )}
        <Button color="primary" onClick={() => close()}>
          {t("forms.shorelineAnalysis.close")}
        </Button>
      
      </DialogActions>
    </Dialog>
  );
};

export default CoastlineEvolutionDialog;
