import React , {  useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import ee from "../../../services/earth-engine"; // @TODO remove this!
import { Box, Grid, Grow } from "@material-ui/core";
import GoogleMap from "../map/GoogleMap";
import ImageChooserCard from "../map/ImageChooserCard";
import ActionsCard from "./ActionsCard";
import { ShapeList } from "..";
import LoadedImagesAccordion from "../map/LoadedImagesAccordion";
import { Actions as Map } from "../../../store/ducks/map";
import { useTranslation } from "react-i18next";
import TourGuider from "../tour/TourGuider";
import { useLocalStorage } from "../../../common/utils";
import { Button } from "@material-ui/core";
import { OverlayHelper  } from "../intertidal/IntertidalZoneDefiner";
//export shapefile dependencies
import Export from "../../../services/export";
import { INTERNALS } from "../../../common/metadata";
import { omit } from "lodash";

//PANSHARPENING 
var geeSharp = require('./../../../common/geeSharp/sharpeners'); // Import the geeSharp module
var entry = require('./../../../common/geeSharp/entry');  


var evaluated;
export class WaterLinesExport{
 static exportShapeFile = function ()  {
    console.log("evaluated", evaluated);
    Export.table.toDevice.asShapefileGroup(   
     [
      {
        features: evaluated.features.map((intertidalArea) => ({//evaluated.getInfo().features.map((intertidalArea)
          ...intertidalArea,
          properties: omit(intertidalArea.properties, [INTERNALS]),
        })),
      },
     ],
     Export.defaultOptions.device.shapefileGroup(
      "output",
      "vectors"
     )
   ); 
 }

}
 
// useStyles is a hook for Material-UI's styling.
const useStyles = makeStyles((theme) => ({
  wrapper: {
    maxHeight: "100vh",
    flexGrow: 1,
    position: "relative",
  },
  mapContainer: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-end",
    flexFlow: "column",
  },
  mapOverlay: {
    position: "absolute",
    top: 0,
    bottom: 0,
    zIndex: 10,
    pointerEvents: "none",
    "& > *": {
      pointerEvents: "none",
      "& > *": {
        pointerEvents: "auto",
      },
    },
  },
  margin: {
    margin: "5px",
  },
  right: {
    textAlign: "right",
  },
}));

// main function
const WaterlineIdentificationAndLevelAssigment = () => {
  // check if the user is drawing a shape
  //const isDrawing = useSelector((state) => state.Map.currentlyDrawing);
  const [isDrawing, setIsDrawing] = useState(false); 

  // get the map coordinates
  const coordinates = useSelector((state) => state.acquisition.coordinates);
  const dispatch = useDispatch();  
  // custom styles 
  const classes = useStyles();
  // get the current language
  const [t] = useTranslation();

  // function that displays a Geometry feature in ee with the given coordinates
  const displayROI = () => {
    if (coordinates) {
      // create a new ROI
      const feature = ee.Feature(ee.Geometry.Polygon([coordinates]));
      // get the border
      const border = feature.buffer(15).difference(feature);
      // display the border in the map in #00A391
      dispatch(Map.addEEFeature(border, "forms.Map.roi", "#00A391", 0.6));
      // center the map on the coordinates
      dispatch(Map.centralizeMap(coordinates));
    }
  };

  let returnToBegin = function(){
    window.location.href = "/main/selection";
  }

  // defines the steps for the tour
  const steps = [
    {
      selector: "#mapScreen",
      content: t("tour.//Map.start"),
    },
    {
      selector: "#imageChooserForm",
      content: t("tour.//Map.imageChooser.info"),
    },
    {
      selector: "#imageChooserSelect",
      content: t("tour.//Map.imageChooser.select"),
    },
    {
      selector: "#imageChooserLoadButton",
      content: t("tour.//Map.imageChooser.click"),
    },
  ];

  // create a localStorage object to check if the user has already seen the tour
  const [isTourOpen, setIsTourOpen] = useLocalStorage("showMapTour", true);

  //start: code step 5
/////////////////////////////////////////////////////////////
// Defining the function to estimate topobathymetry        //
/////////////////////////////////////////////////////////////

///////////////////////////////////////////////////////////
///// Laís Pool  (lais.pool@gmail.com)                /////                        
///// Florianopolis, 05/06/2023                       /////              
///// Code Editor - Earth Engine                      /////                             
///////////////////////////////////////////////////////////
/* 
Algoritmo para inferir valores de topobatimetria na zona de intermaré.
Retirado de Costa etal 2021.

Da primeira etapa, foram extraídas imagens de NDWI cortadas pelos 
vetores. Na segunda etapa é preciso identificar o limite entre água 
e terra em cada uma delas

*/
////////////////////////////////////////////////////////////

var pathImage = 'projects/ee-index-images/assets/delta'; // change this to the path of the asset containing the NWI data (imagens baixadas no passo 4)
var pathTideg = 'projects/ee-tide-gauge/assets/'; // change this to the path of the asset containing the tide gauge data (csv do passo 2)
var nameTideg = 'delta_enchenteTPXO'; // name of the tide gauge observations file
var savePath = 'projects/ee-tide-gauge/assets/'; // change this to the path of the asset where you want to save the results
//var tideGauge = ee.FeatureCollection(pathTideg + nameTideg); // loading tide gauge observations as a ee.FeatureCollection
let tideDataTable = JSON.parse(window.localStorage.getItem("tideData"));
let features = []; 
  tideDataTable.forEach(entry => {
  var point = ee.Geometry.MultiPoint([],"EPSG:4326"); 
  let f1 = ee.Feature(point, { "Dado": entry[0], "Data": entry[1], "Hora":  entry[2] } ); 
  features.push(f1);  
}); 
var tideGauge = ee.FeatureCollection(features);

// algumas definições
var scale = 10;
let coordinatesArray = JSON.parse(window.localStorage.getItem("AOIIntertidal"))
//MapGoogle.centralize(coordinatesArray[0][1], coordinatesArray[0][0]);
var geometry = ee.Geometry.Polygon(
[
  coordinatesArray 
]);

// Define a function to combine the date and time columns into a new column
var combineDateTime = function(feature) {
  var date = ee.String(feature.get('Data'));
  var hour = ee.String(feature.get('Hora'));
  var combinedDateTime = ee.String(date).cat(' ').cat(hour);
  return feature.set('dateTime', combinedDateTime);
};
var updatedCollection = tideGauge.map(combineDateTime);

updatedCollection = updatedCollection.map(function(feature) {
  var dateTimeStr = ee.String(feature.get('dateTime')); 
  var dateTime = ee.Date.parse('dd/MM/yyyy HH:mm:ss', dateTimeStr); //yyyy-MM-dd HH:mm:ss 
  return feature.set('dateTime', dateTime);
});
////print'Updated FeatureCollection:', updatedCollection.limit(10));
tideGauge = updatedCollection;
//var ll = tideGauge.toList(tideGauge.size());
////print"data mais antiga", ll.get(-1));

/*Defining a function for mapping over the lists
var filterValues = function(elem) {
  return ee.Algorithms.If(elem[1].eq(1), elem[0], null);
};
*/      

// Defining the Otsu function
/*
declaration: function otsuThreshold(histogram):
This function was made to find the optimum threshold of a grey scaled image (single band)

    list of parametres:
    • histogram: an histogram found with:
        var histogram = image.reduceRegion({
          reducer: ee.Reducer.histogram(),
          geometry: geometry, 
          scale: 10
        });
        var imageHist = histogram.get('bandName');
      
      • image: the name of the dingle band image
      • bandName: the name of the band in your image
      
    global variables: (none)
    libraries needed: (none)
    return value: threshold value
*/
var thresholdingAlgorithm = function(histogram) { //otsuThreshold
  var counts = ee.Array(ee.Dictionary(histNDWI).get('histogram')); 
  var means = ee.Array(ee.Dictionary(histNDWI).get('bucketMeans')); 
  var size = means.length().get([0]);
  var total = counts.reduce(ee.Reducer.sum(), [0]).get([0]);
  var sum = means.multiply(counts).reduce(ee.Reducer.sum(), [0]).get([0]);
  var mean = sum.divide(total);

  var indices = ee.List.sequence(1, size);
  
  // Compute between sum of squares, where each mean partitions the data.
  var bss = indices.map(function(i) {
    var aCounts = counts.slice(0, 0, i);
    var aCount = aCounts.reduce(ee.Reducer.sum(), [0]).get([0]);
    var aMeans = means.slice(0, 0, i);
    var aMean = aMeans.multiply(aCounts)
        .reduce(ee.Reducer.sum(), [0]).get([0])
        .divide(aCount);
    var bCount = total.subtract(aCount);
    var bMean = sum.subtract(aCount.multiply(aMean)).divide(bCount);
    return aCount.multiply(aMean.subtract(mean).pow(2)).add(
          bCount.multiply(bMean.subtract(mean).pow(2)));
  });

  ////printui.Chart.array.values(ee.Array(bss), 0, means));
  //////print'the threshold is:    ', means.sort(bss).get([-1]));

  // Return the mean value corresponding to the maximum BSS.
  return means.sort(bss).get([-1]);
};

// Function to add datetime property to each image and correct the timezone
var addDatetimeProperty = function(image) {
  /*
  var id = ee.String(image.get('system:id'));  // Get the image ID
  var datetimeStr = id.slice(38, 53);  // Extract the datetime string from the ID 
  // Convert the datetime string to a datetime object
  var datetime = ee.Date.parse('yyyyMMdd\'T\'HHmmss', datetimeStr);*/
  var datetime = ee.Date(image.get("system:time_start"));
  var updatedDatetime = datetime.advance(-3, 'hour'); //for São Paulo Brazil: -3h timeZone
  // Return the image with the added datetime property
  return image.set('datetime', updatedDatetime);
};

// Combined function to calculate time difference and set properties
var calculateTimeDifferenceAndSetProperties = function(image, feature) {
  var imageDate = ee.Date(image.get('datetime'));
  var featureDate = ee.Date(feature.get('dateTime'));
  
  var diff = (featureDate.difference(imageDate, 'second')).abs();

  return feature.set({
    'timeDifference': diff,
    'dateImage': imageDate,
    'dateGauge': featureDate
  });
};

// Function to add the 'tide' value to each feature in a featureCollection
var addTideValue = function(feature) {
  var tideValue = closestFeatureImage.get('Dado_');
  var dateImage = closestFeatureImage.get('dateImage');
  dateImage = ee.Date(dateImage).format('yyyy-MM-dd');
  var dateGauge = closestFeatureImage.get('dateGauge');
  dateGauge = ee.Date(dateGauge).format('yyyy-MM-dd');
  
  return feature.set({
    'Tide': tideValue,
    'dateImage': dateImage,
    'dateGauge': dateGauge
  });
};

// Define function that identifies the waterline
/*
declaration:  function identifyWaterFeature(imagem, geometry, scale, bands, selectThreshold(0.0))
    list of parametres:
    • imagem: 
    • geometry:
    • scale:
    • bands: the name of the band in your image
    • selectThreshold: function
    
    global variables: (none)
    libraries needed: (none)
    return value: geometry of the water feature on each image
*/
var histNDWI = [];
var identifyWaterFeature = function (image,geometry,scale,band){

  var internalBandName = 'ndwi';
  var ndwi = image;
  ////printndwi);

  var histogram = ndwi.reduceRegion({
    reducer: ee.Reducer.histogram(),
    geometry: geometry, 
    scale: 10
  });
  histNDWI = histogram.get('ndwi');
  ////print"histogram",histogram);
  ////print"histNDWI", histNDWI);
  
  var  threshold = thresholdingAlgorithm(histogram.get('histogram'));
  //print ("PRINT 1 - threshold: ", threshold);
  /**
    * Partitions image and reduces to a single vector
  */
  //print"PRINT 2 - NDWI", ndwi);
  
  var intertidalFeature = ee.FeatureCollection(ndwi.clip(geometry).lte(threshold)
        .reduceToVectors({ scale: scale, maxPixels: 1e12, eightConnected: false})
        .filter(ee.Filter.eq("label", 1))
        //.limit(1, "count", false) 
        //.first()  
    );
  ////print"after reducer", intertidalFeature, typeof(intertidalFeature));
  
  var intertidalFeatureList = intertidalFeature.toList(intertidalFeature.size());
  ////print"after to List", intertidalFeatureList, typeof(intertidalFeatureList))
  
  //print"PRINT 3 - intertidalFeature", intertidalFeature);
  //
  if(intertidalFeature.getInfo() === null ){
    return null;
  }else{
    return intertidalFeature.geometry();
  }
};

// Define function for gaussianKernel
/*
declaration:  function linearGaussianFilter(size, mean, sigma)
    list of parametres:
    • size: 
    • mean:
    • sigma:

    global variables: (none)
    libraries needed: (none)
    return value: 
*/
var gaussianKernel = function (size, mean, sigma) {
  var gaussianCurve = function (x, mean, sigma)  {
    var divider = ee
      .Number(sigma)
      .multiply(ee.Number(2).multiply(Math.PI).sqrt());
    var exponent = ee.Number(-1).multiply(
      ee
        .Number(x)
        .subtract(mean)
        .pow(2)
        .divide(ee.Number(2).multiply(ee.Number(sigma).pow(2)))
    );

    return ee.Number(1).divide(divider).multiply(exponent.exp());
  };

  var half = ee.Number(size).divide(2).floor();

  var begin = ee.Number(0).subtract(half),
    end = ee.Number(0).add(half);

  // Get the normal distribution Y value for each X
  // in the interval
  var kernel = ee.List.sequence(begin, end).map(function (i) { return gaussianCurve(i, mean, sigma)});

  var sum = kernel.reduce(ee.Reducer.sum());

  // Normalize each value, so that the sum of the list
  // will be equal to one
  var normalizedKernel = kernel.map(function (val) { return ee.Number(val).divide(sum)});

  return normalizedKernel;
};


// Define function that filters with a gaussian curve
/*
declaration:  function linearGaussianFilter(coordinates)
    list of parametres:
    • coordinates: 

    global variables: (none)
    libraries needed: (none)
    return value: 
*/
var linearGaussianFilter = function (coordinates) {
  var samples = 3;
  var mean = 0;
  var sd = 1;
  var coordinateList = ee.List(coordinates);

  // Setup gauss distribution kernel parameters
  var kernelSize = ee.Number(samples);
  var kernelMean = ee.Number(mean);
  var kernelSd = ee.Number(sd);
  var kernel = gaussianKernel(kernelSize, kernelMean, kernelSd);

  var first = coordinateList.reduce(ee.Reducer.first()),
    last = coordinateList.reduce(ee.Reducer.last());

  var sequence = ee.List.sequence(
    ee.Number(0),
    coordinateList.length().subtract(kernelSize)
  );

  var path = sequence.map(function (index) {
    // Take interval of the kernel size to apply the smoothing
    // and zip it to the kernel, so each element in the new list
    // will be a pair of a 2d point and its weight
    var interval = coordinateList
      .slice(ee.Number(index), ee.Number(index).add(kernelSize))
      .zip(kernel);

    // Map the elements, multiplying their axis values by their weight
    var gaussian = interval.map(function (element) {
      // Each element contains a 2d point (0) and a kernel weight (1)
      var asList = ee.List(element);

      var point = ee.List(asList.get(0));
      var weight = ee.Number(asList.get(1));

      // Now we map the two values (each point dimention), multiplying to the weight
      return point.map( function (value) { return ee.Number(value).multiply(weight)});
    });

    // Sum longitude and latitude separately
    var smoothenLong = gaussian
      .map(function (point){ return  ee.List(point).get(0)})
      .reduce(ee.Reducer.sum());
    var smoothenLat = gaussian
      .map(function (point) { return ee.List(point).get(1)})
      .reduce(ee.Reducer.sum());

    // Return final smoothen point
    return ee.List([smoothenLong, smoothenLat]);
  });

  var smoothen = ee.List([]).add(first).cat(path).add(last);

  // return original coordinates if the kernelSize is less than or equal to the length
  // of the given coordinates, otherwise return smoothen coordinates.
  return ee.Algorithms.If(
    coordinateList.size().lte(kernelSize),
    coordinateList,
    smoothen
  );
};


// Calculate time difference and set properties for each feature
function getClosestFeatureImageAndDado(waterlineImage, tideGauge) {
  var closestCollection = tideGauge.map(function(feature) {
    return calculateTimeDifferenceAndSetProperties(waterlineImage, feature);
  });

  var sortedCollection = closestCollection.sort('timeDifference');
  var closestFeatureImage = sortedCollection.first();
  var tideValue = closestFeatureImage.get('Dado');
  var dateImage = closestFeatureImage.get('dateImage');
  var dateGauge = closestFeatureImage.get('dateGauge');
  
  return {
    closestFeatureImage: closestFeatureImage,
    tideValue: tideValue,
    dateImage: dateImage,
    dateGauge: dateGauge
  };
}


// Masking filter - ndwi
// Input: image collection with NDWI band added 
// Output: image collection with land areas masked
// Description: Mask all land pixels in image NDWI 
//  Values correspond to the following ranges:
//    0.2 – 1 – Water surface;
//    0.0 – 0.2 – Flooding, humidity;
//    -0.3 – 0.0 – Moderate drought, surfaces without water;
//    -1 – -0.3 – Dry, water-free surfaces.

var waterLandFilter = function (image){
  return image.updateMask(image.lt(0.2))
}; 


// Function to filter polygons with more than 20 vertices
// Input: multiPolygons 
// Output: multiPolygons
// Description: Mask all polygons from a feature collection of polygons that have less then 20 vertices
var filterPolygons = function(polygon) {
  var numVertices = ee.List(ee.List(polygon).get(0)).length();
  return ee.Algorithms.If(numVertices.gt(20), polygon, null);
};

// Function to get the polygon with most vertices
// Input: multiPolygons 
// Output: Polygon
// Description: Get the biggest polygon from a freature of multipolygons
var getPolygonWithMostVertices = function(feature) {
  var polygons = feature.geometry().geometries();
  
  var polygonsWithVertexCount = polygons.map(function(polygon) {
    var numVertices = ee.Geometry(polygon).coordinates().flatten().length();
    return ee.Feature(ee.Geometry(polygon)).set('numVertices', numVertices);
  });
  var polygonsCollection = ee.FeatureCollection(polygonsWithVertexCount);
  var sortedPolygons = polygonsCollection.sort('numVertices', false);
  var largestPolygon = sortedPolygons.first().geometry();
  
  return ee.Feature(largestPolygon);
};  

// Define the main function
/*
declaration: function SDB_intertidal(pathImage, tideGauge, savePath):
This function finds the elevation of waterlines found using a threshold technique and tide gauge information

    list of parametres:
    • pathImage: nome of the asset path of the nwi images
    • tideGauge: name and path of the tide gauge file (on assets)
    • savePath: path where the results are going to be saved
      
    global variables: 
      • imageCollection: 
      • histogram: 
      • threshold:
    libraries needed: (none)
    return value: threshold value
*/


//var SDB_intertidal = function(pathImage, tg, savePath) {

var assetList = ee.List(OverlayHelper.imgsToStep5);/*ee.data.listImages(pathImage).images;*/
//var idsList = assetList.map(function(img) { return img.id}); 
var imageCollection = ee.ImageCollection(assetList);//ee.ImageCollection(idsList);
////print"Before datetime:", ee.Date(imageCollection.first().get("system:time_start")).format(null, "UTC"));
var imageCollectionWithDatetime = imageCollection.map(addDatetimeProperty);
////print'Image Collection with Datetime:', imageCollectionWithDatetime);
////print"After datetime:", (imageCollectionWithDatetime.first().get("datetime"))); 

var numImages = imageCollectionWithDatetime.size();
//var mergedPointsWithTide = ee.FeatureCollection([]);
var closestFeatureImage; 

////print'-- begin waterline print --');

console.log("Step 5.1");
//////////////////// FOR LEGEND
// Create a legend panel
/*
var legend = ui.Panel({
style: {
  position: 'bottom-right',
  padding: '8px 15px'
}
});

// Create a title for the legend
var legendTitle = ui.Label({
value: 'Waterline Legend',
style: {fontWeight: 'bold', fontSize: '18px', margin: '0 0 10px 0', padding: '0'}
});
legend.add(legendTitle);

// Define a function to add a color and label to the legend
var addColorAndLabel = function(color, label) {
// Create a label
var colorBox = ui.Label({
  style: {
    backgroundColor: color,
    padding: '8px',
    margin: '0 0 4px 0'
  }
});

// Create a panel with the color box and the label
var legendEntry = ui.Panel({
  widgets: [colorBox, ui.Label(label)],
  layout: ui.Panel.Layout.Flow('horizontal'),
  style: {margin: '0 0 4px 0'}
});
legend.add(legendEntry);
};
*/
///////////////////// END LEGEND
let n = numImages.getInfo();
evaluated = ee.FeatureCollection([]);
console.log("External loop", n);
for (var i = 0; i < n; i++) { 

  var ndwi = ee.Image(imageCollectionWithDatetime.toList(numImages).get(i)).select('ndwi');
  //Map.addLayer(ndwi, {min:-1, max:1}, "NDWI" +i, false);
  
  ///// CREATE POLYGON OF "NON WATER FEATURE" TO INTERSEPT:
  //var waterLandImage = ndwi.map(waterLandFilter); 
  
  var histogram = ndwi.reduceRegion({
    reducer: ee.Reducer.histogram(),
    geometry: geometry, 
    scale: 10
  });
  histNDWI = histogram.get('ndwi');
  
  var threshold = thresholdingAlgorithm(histogram.get('histogram'));
  //console.log('threshold', threshold)
  var minPixelValue = ndwi.reduceRegion({reducer: ee.Reducer.min(),geometry: geometry,scale: 10, maxPixels: 1e9});
  var maxPixelValue = ndwi.reduceRegion({reducer: ee.Reducer.max(),geometry: geometry,scale: 10, maxPixels: 1e9});
  var stdDevPixelValue = ndwi.reduceRegion({reducer: ee.Reducer.stdDev(),geometry: geometry,scale: 10, maxPixels: 1e9});
  stdDevPixelValue = stdDevPixelValue.get('ndwi').getInfo();
  //print"stdDev value", stdDevPixelValue);  
  var THajustment = ee.Number(stdDevPixelValue).add(1);
  var thresholdValue = ee.Number(threshold).subtract(ee.Number(THajustment).multiply(ee.Number(threshold))); 
  ////print'thresholdValue', thresholdValue)
  var waterLandImageFeature = ee.FeatureCollection(ndwi.clip(geometry).lt(thresholdValue)
      .reduceToVectors({ scale: scale, maxPixels: 1e12, eightConnected: false})
      .filter(ee.Filter.eq("label", 1))
  );
 //console.log("waterLandImageFeature", waterLandImageFeature);

  var filteredWaterLandImageFeature = ee.Geometry.Polygon(ee.List(getPolygonWithMostVertices(waterLandImageFeature)
    .geometry().coordinates()).get(0));
  var datetime = ee.Date(ndwi.get("datetime")).format().getInfo();  // Get datetime as string
  
  var result = getClosestFeatureImageAndDado(ndwi, tideGauge);
  var closestFeatureImage = result.closestFeatureImage;
  var tideValue = result.tideValue;
  var dateImage = result.dateImage;
  var dateGauge = result.dateGauge;  
  var band = "ndwi";
  var waterSegment = identifyWaterFeature(ndwi, geometry, scale, band); //, selectThreshold(-1.0)
  console.log("PRINT 4 - waterSegment: "+i /*, waterSegment*/);

  if(waterSegment !== null){
    var polygons = waterSegment.coordinates();
    //print"polygons", polygons);
    
    var filteredPolygons = polygons.map(filterPolygons).removeAll([null]);
    
    waterSegment = ee.Geometry.MultiPolygon(filteredPolygons); 
    ////print"PRINT 4 - waterSegment: "+i, waterSegment);
    
    polygons = waterSegment.coordinates();
    var processedPolygons = polygons.map(function(polygon) {
      var size = ee.List(polygon).size();
      return ee.Algorithms.If(size.gt(1), [ee.List(polygon).get(0)], polygon);
    });

    // Create a new MultiPolygon with the processed polygons
    var waterSegment = ee.Geometry.MultiPolygon(processedPolygons); 
    ////print"PRINT 4 - waterSegment: "+i, waterSegment);  
    //Map.addLayer(ee.FeatureCollection(waterSegment), {color: 'red'}, 'Filtered waterSegment: '+i, false);
    ////printnonsense)
    var waterline = ee.FeatureCollection([]);
    let coordinatesSize = waterSegment.coordinates().size().getInfo();
    let dtGaugeFormated =  ee.Date(dateGauge).format('yyyy-MM-dd HH:mm');
    let dtImageFormated = ee.Date(dateImage).format('yyyy-MM-dd HH:mm');
    for (var ii=0; ii <coordinatesSize; ii++) {
      
      let coordinates = waterSegment.coordinates().get(ii);
      ////print"PRINT 4.1 - waterline before difference", coordinates)
      var wl = ee.FeatureCollection(ee.Geometry.MultiLineString(coordinates)
        .intersection(geometry)); //.buffer(-10)
      ////print"PRINT 4.2 - waterline after difference: "+ii, wl);
      ////printee.List(wl.first()))
      //console.log("wl 1", wl.getInfo());
      var checkCoordinates = wl.first().geometry().coordinates();
      
      if (ee.List(checkCoordinates).size().gt(0).getInfo()) {
        var type2test = ee.Algorithms.ObjectType(ee.List(ee.List(checkCoordinates).get(0)).get(0));
          if (type2test.equals('List').getInfo()) {
            var coordsList = ee.List(ee.List(checkCoordinates)).get(-1);
            wl = ee.FeatureCollection(ee.Geometry.MultiLineString(coordsList));
            ////print"PRINT 4.4 - waterline after correction: ", wl);  // Print the corrected wl for debugging
          } else {
            //print"Error: type2test is not 'List', it is", type2test);
          }   
        wl = ee.FeatureCollection(ee.Geometry.MultiLineString(
        linearGaussianFilter((wl).geometry().coordinates()))); 
       // console.log("4.4 - waterline after smooth: "+ii /*, wl*/);
       // console.log("wl 2", wl.getInfo());
        //console.log("dateGauge", dateGauge.getInfo());
        //console.log("tideValue", tideValue.getInfo());
        //console.log("dateImage", dateImage.getInfo());
        //console.log("values","dateGauge", dateGauge,"tideValue",tideValue,"dateImage",dateImage,"datetime ", datetime );
        var updatedWl = wl.map(function(feature) {
          return ee.Feature(feature.geometry(), feature.toDictionary())
            //.set('id', 'WL_' + datetime + '_' + ii)
            .set('dateTime', datetime)
            .set('tideValue', tideValue)
            .set('dateGauge', dtGaugeFormated) 
            .set('dateImage', dtImageFormated); 
        });
       // console.log("wl 3", updatedWl.getInfo());
        waterline = waterline.merge(updatedWl);
        
      } else {
        console.log("Error: coordinates list is empty.");
      } 
    }
    console.log("PRINT 5 - Waterline "+i /*, waterline*/);
    
    
    var convertToLineString = function(feature) {
      var coords = ee.List(ee.List(feature.geometry().coordinates()).get(0));
      var proj = 'EPSG:4326';
      return ee.Feature(ee.Geometry.LineString(coords, proj), feature.toDictionary()); //{"system:time_start": ee.Date(ndwi.get("datetime")).format(null)}
    };
    
    var waterlines = waterline.map(convertToLineString);
    
    console.log("PRINT 6 - waterlines: " + i /*, waterlines*/);
    
    var colors = ['#FF0000', '#00FF00', '#0000FF', '#FFFF00', '#00FFFF', '#FF00FF', '#FFA500', '#800080', '#008080', '#000000', '#FFA500', '#800080', '#008080'];
    var color = colors[i % colors.length];
    
    //Map.addLayer(waterline,{'color': color },'waterline: ' + i);
    console.log("-- end waterline print --");
    
    //addColorAndLabel(color, datetime); // Add color and datetime to the legend
  }else{
    console.log("Error image: "+ i); 
  }
  try{
   console.log("merging waterlines...");
   evaluated = evaluated.merge(waterlines);
   console.log("waterline merged.");
  }catch(e){
    console.error("error merging waterlines", e);
  }
  console.log("end loop iteration", i);
  if(i === n-1){
    console.log("End of processing - breaking...");
    break;
  }
} 
console.log("Printing waterlines...."); 
try{
  console.log("executing getInfo for evaluated variable...");
  let evaluatedEE = evaluated;
  evaluated = evaluated.getInfo();
  //WaterLinesExport.exportShapeFile();
  console.log("Water lines....", evaluated); 
  console.log("Trying to plot linestrings on map..."); 


  /*
  const collection = ee.FeatureCollection(feature);
  const list = ee.List(collection.toList(collection.size()));
  const content = yield evaluate(list);
  const colors = Array.isArray(color) ? color : [color];
  let colorIndex = 0;
  const shapes = content.map((element) => {
    const shape = Map.addShape(element, colors[colorIndex], opacity, group);
  */
  //create shape object
  let shapes = [];
  console.log("Init map current");
  if(evaluated.length > 0){
    const lat = features[0].geometry.coordinates[0][0];
    const lng = features[0].geometry.coordinates[0][1];
    let mapElement = document.getElementById("google-maps");

    let mapRef = new window.google.maps.Map(mapElement, {
      center: { lat: lat, lng: lng },
      zoom: 10,
      scaleControl: true,
      streetViewControl: false,
      mapTypeControl: true,
      mapTypeControlOptions: {
        style: window.google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
        position: window.google.maps.ControlPosition.TOP_LEFT,
      },
      fullscreenControl: true,
      styles: [
        {
          featureType: "poi",
          stylers: [{ visibility: "off" }],
        },
      ],
    });
    mapRef.current = mapRef;
    console.log("map current", mapRef);


    mapRef.current = mapRef; 
    console.log("map",mapRef);
    let colorIndex = 0;
    evaluated.features.map( geoJson => {
      //toGeoJSON()
      const shape = mapRef.data.addGeoJson(geoJson, { idPropertyName: "uid" })[0];
      shape.setProperty("customStyle", {
        fillOpacity: 1,
        fillColor: "red", //colors[colorIndex]
        strokeWeight: 2,
        strokeColor: "red",
        strokeOpacity: 1, 
      });
      shapes.push(shape);
      if (colorIndex < colors.length - 1) { 
        colorIndex++;
      }else{
        colorIndex = 0;
      }
    });
}

  console.log("Plot map ended.", shapes);
}catch(e){
  console.error(e);
}

  //end: code step 5
  return (
    <Box className={classes.wrapper}>
      <Grow in={!isDrawing} unmountOnExit>
        {
          // this grid is placed over the map to display the image chooser and the actions card
          // @TODO: remove this grid and use contextual menus instead
        }
        <Grid
          container
          justifyContent="center"
          spacing={0}
          id="mapScreen"
          className={classes.mapOverlay}
        >
          <Grid item xs={9} className={classes.mapContainer}>
            <ShapeList />
            <ImageChooserCard />
            <ActionsCard />
            <div>
              &nbsp;&nbsp;&nbsp;<Button color="primary" variant="contained"className={classes.button} onClick= { returnToBegin } style={{cursor:"pointer"}} > {t("forms.acquisition.5.bathymetry.returnToBegin")}</Button>
              <br /><br /><br /><br />
            </div>
          </Grid>
          <Grid item xs={3}>
            <LoadedImagesAccordion />
            
          </Grid>
        </Grid>
      </Grow>
      {
        // the google map
      }
       <GoogleMap
          style={{ width: "100%", height: 500 }} 
        />
     
      <TourGuider
        steps={steps}
        isOpen={isTourOpen}
        setIsTourOpen={setIsTourOpen}
      />
    </Box>
  );
};

export default WaterlineIdentificationAndLevelAssigment;
