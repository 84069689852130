import { Grid, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import HomePageLayout from "../../components/homepage/HomePageLayout";
import bathymetryThumb from "../../../resources/bathymetryThumb.jpg";
import shorelinesThumb from "../../../resources/shorelinesThumb.jpg";
import csqueezeThumb from "../../../resources/cqueezeThumb.jpg";
import intermareThumb from "../../../resources/intermares_logo.png";
import AlgorithmCard from "../../components/acquisition/AlgorithmCard";
import { push } from "connected-react-router";
import { useDispatch } from "react-redux";
import ReactGA from "react-ga";
import { ee } from "../../../services/earth-engine";
import { acquireFromDate } from "../../../algorithms/satellite/sentinel";
import { ContactSupportOutlined } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: "flex",
    flexFlow: "column",
    flexGrow: 1,
  },
  header: {
    backgroundColor: theme.palette.primary[400],
    padding: theme.spacing(6),
  },
  title: {
    color: "white",
  },
  container: {
    display: "flex",
    alignItems: "center",
    flexFlow: "column",
  },
  content: {
    width: "80vw",
    maxWidth: "1000px",
    marginTop: theme.spacing(-9),
  },
  instructions: {
    paddingTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
  },
  grid: {
    margin: theme.spacing(6, 0),
  },
  item: {
    maxWidth: "25%",
    maxHeight: "500px",
  },
}));

const SelectionPage = () => {
  const dispatch = useDispatch();
  const handleChoice = (name) => {
    ReactGA.event({
      category: "Algorithm",
      action: "ChooseAlgorithm",
      value: name.split(" ", 1)[0],
    });

    name =  name.toLowerCase();
    if(name.indexOf("intertidal")!== -1 || name.indexOf("intermaré") !== -1){
      dispatch(push("intertidal/1"))
      return;
    }


    name.indexOf("shoreline") !== -1 ||
    name.indexOf("linha de costa") !== -1
      ? dispatch(push("acquisition/1"))
      : name.indexOf("batimetria")!== -1 || name.indexOf("bathymetry") !== -1
      ? dispatch(push("bathymetry/1"))
      : dispatch(push("csqueeze/1"));
  };

  //clear intertidal parameters before module init
 //window.sessionStorage.removeItem("AOIIntertidal");
 //window.sessionStorage.removeItem("tideData");
 //window.sessionStorage.removeItem("intertidalPeriodData");    
 

  const [t] = useTranslation();
  const classes = useStyles();
  const availableAlgorithms = [
    {
      name: t("forms.algorithm.shoreline"),
      image: shorelinesThumb,
      description: t("forms.algorithm.shorelineDesc"),
      enabled: true,
    },
    {
      name: t("forms.algorithm.bathymetry") + " (beta)",
      image: bathymetryThumb,
      description: t("forms.algorithm.bathymetryDesc"),
      enabled: true,
    },
    {
      name: t("forms.algorithm.csqueeze") + " (beta)",
      image: csqueezeThumb,
      description: t("forms.algorithm.csqueezeDesc"),
      enabled: true,
    },
    {
      name: t("forms.algorithm.intermare") ,
      image: intermareThumb,
      description: t("forms.algorithm.intermareDesc"),
      enabled: true,
    },
  ];
  scriptToTest();

  return (
    <HomePageLayout>
      <div className={classes.wrapper}>
        <Grid item xs={12} className={classes.header} >
          <div>
            <Typography className={classes.title} variant="h4" align="center">
              {t("forms.algorithm.title")}
            </Typography>
          </div>
        </Grid>
        <Grid
          container
          spacing={4}
          justifyContent="center"
          id="algorithmchooser"
          className={classes.grid}
        >
          {
            // map the satellites to satellite cards
            availableAlgorithms.map((algorithm, i) => (
              <Grid
                item
                key={i}
                xs={4}
                id={algorithm.name}
                className={classes.item}
              >
                <AlgorithmCard
                  name={algorithm.name}
                  image={algorithm.image}
                  description={algorithm.description}
                  onChoose={() => handleChoice(algorithm.name)}
                  enabled={algorithm.enabled}
                />
              </Grid>
            ))
          }
        </Grid>
      </div>
    </HomePageLayout>
  );

  function scriptToTest() {
    //write here code to test from GEE Code Editor on CASSIE
    return false;
  }
};

export default SelectionPage;
